
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;

  > .col,
  > [class*="col-"] {
    padding-right: 12px;
    padding-left: 12px;
  }
}