@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a:hover,
a:focus,
a:active {
	outline: none;
	text-decoration: none;
	box-shadow: none;
}

.row.custom-row {
	margin-right: -10px;
	margin-left: -10px;

	> .col,
	> [class*="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.transak_modal {
	z-index: 1200 !important;
}

.transak_modal-overlay {
	z-index: 1199 !important;
}
.transak_close {
	background-color: $title !important;

	& path {
		fill: white !important;
	}
}

html, body, * {
	font-family: "Inter", sans-serif;
}
